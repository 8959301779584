const appConfig = {
  "name": "appstore-web",
  "version": "0.1.0",
  "stage": "prod",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "okta": {
        "issuer": "https://okta.mit.edu/oauth2/aus6sh93rjqnQuszg697",
        "clientId": "no_client_id",
        "redirectUri": "https://apps.atlas-apps.mit.edu",
        "scopes": []
      },
      "api": {
        "domain": "global.api.mit.edu",
        "paths": {}
      }
    }
  ]
};
export default appConfig;