import { createSlice } from '@reduxjs/toolkit'

export interface AppConfigState {
  appName: string
  appDescription: string
  appContact: string
  appTheme: string
  googleAnalyticsId: string
}

const initialState: AppConfigState = {
  appName: 'MIT App Store',
  appDescription: 'An MIT Atlas app.',
  appContact: 'help@mit.edu',
  appTheme: 'medical',
  googleAnalyticsId: 'G-R8E68YJSPK'
}

// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice
export const appConfigSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {}
})

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions

export default appConfigSlice.reducer
