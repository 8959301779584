import { configureStore } from '@reduxjs/toolkit'
import { apiMiddleware, apiReducers } from 'api'
import AppConfigReducer from './slice/AppConfigSlice'

export const store = configureStore({
  reducer: {
    appConfig: AppConfigReducer,
    ...apiReducers
  },
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), ...apiMiddleware]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
