import { getStage, Region, RegionalConfig } from '@mit/aws-react'
import appConfig from '../app-config-global'

type EnvReturn = 'develop' | 'release' | 'master'

export const getEnv = (): EnvReturn => {
  switch (getStage(appConfig)) {
    case 'develop':
      return 'develop'
    case 'release':
      return 'release'
    case 'master':
      return 'master'
  }
  return 'master'
}

export const getEnvironmentUrl = (url: string, replaceVariableName: string = 'env'): string => {
  const env = getEnv()
  let urlEnv: string = ''

  switch (env) {
    case 'develop':
      urlEnv = 'hecsf2web01'
      break
    case 'release':
      urlEnv = 'hecsh2web01'
      break
    case 'master':
      urlEnv = 'hecps1web01'
      break
  }

  return url.replace(replaceVariableName, urlEnv)
}

export const getActiveRegion = (): RegionalConfig => {
  const region = new Region(appConfig)

  return region.getActiveRegionConfig()
}
